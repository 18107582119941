<template>
  <div class="wrapper">
    <b-col md="12" class="mt-sm-3 mt-md-0 card card-reset" style="padding: 15px">
      <select-places
        :type="{ delivery: true }"
        :place-title="$i18n.t('drivers.labels.placesDelivery')"
        :place-text="$i18n.t('drivers.labels.selectPlaces')"
        @eventPlaces="onEventPlaces($event)"
      />
    </b-col>

    <b-col v-if="placeSelected" md="12" class="mt-sm-3 mt-md-0 card card-reset" style="padding: 15px">
      <b-row class="d-flex justify-content-center mt-3">
        <b-col md="12" class="mt-sm-3 mt-md-0">
          <b-form-group :label="$i18n.t('drivers.labels.carCategory')">
            <b-form-select
              id="fleet-category"
              class="mb-1"
              v-model="modal.metadata.fleetCategory"
              :options="fleetCategoryOptions"
              :disabled="block_ui"
              type="text"
              style="min-height: 40px"
            >
              <template #first>
                <option :value="null" disabled>{{ $i18n.t('drivers.labels.selectCategory') }}</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="12" class="mt-sm-3 mt-md-0">
          <b-form-group :label="$i18n.t('drivers.labels.carModel')">
            <b-form-select
              id="fleet-model"
              class="mb-1"
              v-model="modal.metadata.fleetModel"
              :options="fleetModelOptions"
              :disabled="block_ui"
              type="text"
              style="min-height: 40px"
            >
              <template #first>
                <option :value="null" disabled>{{ $i18n.t('drivers.labels.selectModel') }}</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="12" class="mt-sm-3 mt-md-0">
          <p>{{ $i18n.t('drivers.labels.reasonSchedule') }}</p>
          <b-form-textarea id="textAreaReasonChedule" v-model="reasonText" rows="3" :placeholder="$i18n.t('drivers.labels.typeHere')" max-rows="6" />
        </b-col>
      </b-row>
    </b-col>

    <b-card v-if="placeSelected">
      <b-row>
        <b-col>
          <span class="d-block w-100 text-center">
            {{ $i18n.t('modalWaitingDelivery.texts.selectEndDeliveryDate') }}
          </span>
        </b-col>
      </b-row>

      <b-row class="d-flex justify-content-center mt-3">
        <b-col md="3">
          <datepicker v-model="schedule_date" v-bind="date_picker_props" :lang="getLanguage" />
        </b-col>

        <b-col md="3">
          <b-button id="button_update_booking" :disabled="!is_valid_form" variant="success" @click="$_confirmSchedule">
            {{ $i18n.t('modalWaitingDelivery.texts.scheduleDelivery') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-col class="card card-reset" style="padding: 15px">
      <b-row>
        <b-col class="text-center">
          <b-button id="driver-view-cancel-booking" class="font-weight-bold" variant="danger" :disabled="!cancelButtonVisible" @click="$_confirmCancelDelivery">
            {{ $i18n.t('modalVehicleDelivery.button.cancelDelivery') }}
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import SCHEDULE_CAR from '@graphql/booking/mutations/schedule-car.gql';
import SCHEDULE_DELIVERY from '@graphql/schedules/mutations/create-schedule-delivery.gql';
import { currentLanguage } from '@utils/language';
import SelectPlaces from '@components/shared/SelectPlaces';
import { enumCarsCategory, enumCarsModel } from '@utils/car';
import { mapGetters } from 'vuex';

import CANCEL_DELIVERY from '@graphql/schedules/mutations/cancel-delivery.gql';

export default {
  name: 'ScheduleModule',

  components: {
    SelectPlaces,
  },

  props: {
    driver: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modal: {
        metadata: {
          fleetCategory: '',
          fleetModel: '',
        },
      },
      schedule_date: null,
      schedule_mutation: SCHEDULE_CAR,
      placeSelected: null,
      cancel_delivery_mutation: CANCEL_DELIVERY,
      reasonText: '',
      fleetCategoryOptions: [...enumCarsCategory],
      fleetModelOptions: [...enumCarsModel],
    };
  },

  computed: {
    ...mapGetters({
      deliveryScheduleId: 'schedule/deliveryScheduleId',
    }),
    ...mapGetters('driver', {
      profile: 'header',
    }),
    ...mapGetters('env', {
      envSchedules: 'schedules',
    }),
    driverId() {
      const currentDriverId = this.driver.driver ? this.driver.driver.id : this.driver.id;
      return currentDriverId;
    },
    driverStep() {
      return this.profile ? this.profile.step : '';
    },
    cancelButtonVisible() {
      const hasStep = ['DELIVERY_PENDING', 'DELIVERY_SCHEDULED', 'DELIVERY_NOSHOW', 'PAYMENT_PENDING', 'PAYMENT_PENDING_PAYMENT'].includes(this.driverStep);
      const hasCognitoGroup = this.$store.getters['user/groups'].includes('RULE:CANCEL:DELIVERY');
      if (hasStep && hasCognitoGroup) return true;
      return false;
    },
    booking() {
      if (this.driver && this.driver.booking) {
        return this.driver.booking;
      }
      return null;
    },
    car() {
      if (this.booking) {
        return this.booking.car;
      }
      return null;
    },
    getLanguage() {
      return currentLanguage;
    },
    date_picker_props() {
      return {
        type: 'datetime',
        timePickerOptions: {
          start: '07:00',
          step: '00:15',
          end: '22:30',
        },
        shortcuts: false,
        clearable: false,
        editable: false,
        notBefore: this.today,
        notAfter: this.week,
        width: 'auto',
        format: 'DD/MM/YYYY HH:mm',
      };
    },

    is_valid_form() {
      const fleetCategorySelect = this.modal.metadata.fleetCategory !== '';
      const fleetModelSelect = this.modal.metadata.fleetModel !== '';

      return !!this.schedule_date && this.$moment(this.schedule_date).isValid(true) && fleetCategorySelect && fleetModelSelect;
    },

    today() {
      if (this.$moment().format('HH') > 18) {
        // If date > 18 send to another day
        return this.$moment().add(8, 'hours').format();
      }
      return this.$moment().format();
    },

    week() {
      return this.$moment().add(this.envSchedules.schedule, 'weeks').format();
    },
  },

  methods: {
    $_confirmCancelDelivery() {
      const configSwal = {
        type: 'warning',
        title: this.$i18n.t('modalVehicleDelivery.texts.cancelRental'),
        text: this.$i18n.t('modalVehicleDelivery.texts.toCancelDelivery'),
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('modalVehicleDelivery.words.close'),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('modalVehicleDelivery.words.confirm'),
        confirmButtonClass: 'driver-container-finish-booking-button',
        showLoaderOnConfirm: true,
        preConfirm: () => this.cancelDelivery(),
      };
      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;
          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalVehicleDelivery.texts.deliveryCanceled'),
            timer: 2000,
            text: '',
            showCancelButton: false,
            showConfirmButton: false,
          };
          this.$swal(configSwalSuccess).then(val => {
            this.$emit('finished');
          });
        })
        .catch(err => {
          this.$log.logError(err);
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalVehicleDelivery.texts.cancelRental'),
            text: this.$i18n.t('modalVehicleDelivery.texts.couldNotCancelDelivery'),
          };
          this.$swal(configSwalFail);
        });
    },
    async cancelDelivery() {
      const booking = this.booking ? this.booking.id : null;
      const car = this.car ? this.car.id : null;
      const schedule = this.deliveryScheduleId ? this.deliveryScheduleId : null;
      return this.$apollo
        .mutate({
          mutation: this.cancel_delivery_mutation,
          variables: {
            input: {
              driver: this.driverId,
              booking,
              car,
              schedule,
            },
          },
        })
        .then(response => {
          const data = response.data.rentalCancel;
          let hasCar = false;
          let hasSchedule = false;
          if ((!car && !data.car) || (car && data.car)) {
            hasCar = true;
          }
          if ((!schedule && !data.schedule) || (schedule && data.schedule)) {
            hasSchedule = true;
          }
          if (!data.driver || !data.booking || !hasCar || !hasSchedule) {
            return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.toCancelDelivery'));
          }
          return true;
        })
        .catch(err => {
          this.$log.logError(err);
          return Promise.reject(this.$i18n.t('modalVehicleDelivery.texts.couldNotCancelDelivery'));
        });
    },
    $_confirmSchedule() {
      const configSwal = {
        type: 'warning',
        title: this.$i18n.t('modalWaitingDelivery.texts.scheduleDelivery'),
        text: this.$i18n.t('modalWaitingDelivery.texts.wishYouScheduleThisDeliveryTo', {
          date: this.$moment(this.schedule_date).format('DD/MM/YYYY [às] HH:mm').toString(),
        }),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('modalWaitingDelivery.words.schedule'),
        confirmButtonClass: 'delivery-modal;-schedule-car-button',
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('modalWaitingDelivery.words.cancel'),
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          if (!this.is_valid_form) throw new Error(this.$i18n.t('modalWaitingDelivery.texts.invalidForm'));
          await this.$_scheduleCar();
        },
      };

      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;

          const configSwalSuccess = {
            type: 'success',
            title: this.$i18n.t('modalWaitingDelivery.texts.deliveryWasSchedule'),
            timer: 2000,
            text: '',
            showConfirmButton: false,
            showCancelButton: false,
            onAfterClose: async () => {
              await this.$_refresh();
            },
          };

          this.$swal(configSwalSuccess);
        })
        .catch(err => {
          const configSwalFail = {
            type: 'error',
            title: this.$i18n.t('modalWaitingDelivery.texts.couldNotPossibleScheduleThisDelivery'),
            text: err,
            onAfterClose: async () => {
              await this.$_refresh();
            },
          };

          this.$swal(configSwalFail);
        });
    },
    async $_scheduleCar() {
      await this.$apollo.mutate({
        mutation: SCHEDULE_CAR,
        variables: {
          input: {
            driver: this.driver.id,
            car_scheduled_at: this.schedule_date,
          },
        },
      });
      return this.$apollo.mutate({
        mutation: SCHEDULE_DELIVERY,
        variables: {
          input: {
            driver: this.driver.id,
            type: 'NEW_DRIVER',
            place: this.placeSelected.value,
            comments: this.reasonText,
            scheduled_at: this.schedule_date,
            metadata: {
              conductor: 'DRIVER',
              fleetCategory: this.modal.metadata.fleetCategory,
              fleetModel: this.modal.metadata.fleetModel,
            },
            forceSchedule: true,
          },
        },
      });
    },

    async $_refresh() {
      this.schedule_date = null;
      await this.$store.dispatch('driver/signupStatus');
      await this.$emit('refreshDataTable');
      await this.$root.$emit('bv::hide::modal', 'driverSignupSchedule');
    },

    onEventPlaces($event) {
      this.placeSelected = $event;
    },
  },
};
</script>

<style lang="scss" scoped>
.mx-input {
  cursor: default !important;
}
.mx-calendar-header {
  & > a.mx-icon-last-year,
  & > a.mx-icon-next-year {
    display: none !important;
  }
}
</style>
